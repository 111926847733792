import ixigo from './images/ixigo.webp';
import turningCloud from './images/turningCloud.webp';

const experience = [
    {
        "id": 1,
        "organization": {
            "name": "ixigo",
            "link": "https://www.ixigo.com",
            "logo": ixigo,
        },
        "duration": "Feb 2022 - Present",
        "description": [
            {
                "duration": "Apr 2024 - Present",
                "role": "Senior Software Engineer - I",
                "responsibilities": [
                    'Preparing LLDs and developing new features to enhance <a href="https://www.elitedesk.in" target="_blank" rel="noopener noreferrer">Elitedesk <span className="fa fa-external-link"></span></a> - a SaaS product that allows to build complex conversational bots to address customer queries without developer intervention - with TDD approach, while integrating the same across multiple organizations',
                    "Created and published a package for UI components consumed by other LOBs",
                ],
            },
            {
                "duration": "Apr 2023 - Apr 2024",
                "role": "Software Engineer - II",
                "responsibilities": [
                    "Collaborated with team to design and develop the architecture of Elitedesk using ExpressJS and MongoDB, setup and developed the frontend for Elitedesk using ViteJS + ReactJS",
                    "Migrated the existing chat support to Elitedesk with zero downtime, improving chat containment rate by 13% and product NPS (Net Promoter Score) by 11%",
                    'Revamped the UI for <a href="https://www.ixigo.com/support" target="_blank" rel="noopener noreferrer">customer support and chat page <span className="fa fa-external-link"></span></a> as per the organization\'s new design language used by multiple organizations using ViteJS + ReactJS, along with unit tests written in Vitest',
                ],
            },
            {
                "duration": "Feb 2022 - Apr 2023",
                "role": "Software Engineer - I",
                "responsibilities": [
                    "Transformed the existing API-driven chat support system into an event-driven system using socket.io, resulting in elimination of all API calls for exchanging messages and 100% reduction in messages drop-rate; implemented an internal communication system to replace Redis pub-sub",
                    "Developed multiple ReactJS components for a component library used across organization",
                    "Developed various features of organization's customer support page built on MERN stack",
                    "Worked on various internal tools and CMS used by multiple teams across organization",
                ],
            },
        ],
    },
    {
        "id": 2,
        "organization": {
            "name": "TurningCloud Solutions Private Limited",
            "link": "https://www.turningcloud.com/",
            "logo": turningCloud,
        },
        "duration": "Oct 2020 - Feb 2022",
        "description": [
            {
                "duration": "Oct 2020 - Feb 2022",
                "role": "React Developer",
                "responsibilities": [
                    'Worked on frontend development - integration of REST APIs using Redux, conversion of design prototypes into HTML/CSS code, creation of flawless UX using ReactJS - of various modules of <a href="https://www.supplymint.in" target="_blank" rel="noopener noreferrer">Supplymint <span className="fa fa-external-link"></span></a>',
                    "Utilized IndexedDB to store frequently used data, resulting in 90% reduction of associated API calls",
                    "Developed major form components using Formik",
                ],
            },
        ],
    },
];

export default experience;